<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
       <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>运营管理</el-breadcrumb-item>
      <el-breadcrumb-item>首页轮播图</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card style=" overflow: auto">
      <el-row>
        <el-col :span="3.3" class="mr15">
        <div class="grid-content bg-purple-dark">
            <el-button class="el-buttonr" size="mini"  type="success" @click="outerVisibler = true">新增</el-button>
          </div>
          </el-col>
        <el-col class="mr15" :span="3.3">
          <el-input @input="queryname" v-model="queryInfo.keyword" size="mini" placeholder="关键词"   >
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </el-col>
        <!-- 城市筛选 -->
           <el-col class="mr15" :span="3.3" >
         <v-distpicker
                @city="oncity"
                class="v-distpicker"
                :province="queryInfo.province"
                :city="queryInfo.city"
                hide-area>
                </v-distpicker>
              </el-col>
      </el-row>

      <!--商品数据展示区域-->
      <el-table
        v-loading="loading"
        :data="tableData"
        style="width: 1500px"
        ref="singleTable"
        highlight-current-row
        border
         :header-cell-style="{'text-align':'center'}"    
       :cell-style="{'text-align':'center'}"
        row-key="id"
        stripe
      >

        <el-table-column prop="activityImgUrl" label="封面链接" width="250" show-overflow-tooltip>
       <template slot-scope="scope">
            <img @click="ondialogimgsrc(scope.row.activityImgUrl)" style="margin: 0 0 0 20px" :src="scope.row.activityImgUrl" alt=""  width="150px"/>
          </template>
        </el-table-column>
       
        <el-table-column label="跳转活动位置" width="250"  show-overflow-tooltip>
          <template slot-scope="scope">
          <span @click="onactivityurl(scope.row.detailsImgAndPageUrl)">
       {{scope.row.detailsImgAndPageUrl}}
       </span>
       </template>
        </el-table-column>
   
         <el-table-column prop="name" label="详情页跳转位置" width="250" show-overflow-tooltip></el-table-column>
          <el-table-column prop="jumpPosition" label="跳转位置" width="250" show-overflow-tooltip></el-table-column>
        <el-table-column  prop="showHide" label="显示状态" width="80" show-overflow-tooltip>
          <template slot-scope="scope" >
  
            <el-switch v-model="scope.row.showHide" @change="showHidece(scope.row.showHide,scope.row)"  active-color="#13ce66" inactive-color="#ff4949">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="cityName" label="城市" width="100" ></el-table-column>
        <el-table-column label="操作" min-width="100" fixed="right">
        <template slot-scope="scope">
          <el-button @click="modificationressVisiblier(scope.row.id)" size="mini" type="text" >编辑</el-button>
          <el-popconfirm @confirm="deleter(scope.row.id)" style="padding-left: 20px;" title="确定删除此内容吗？">
          <el-button  slot="reference" size="mini" type="text">删除</el-button>
          </el-popconfirm>
           </template>
        </el-table-column>
      </el-table>
      <!--分页-->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :page-sizes="[10,]"
        :page-size="queryInfo.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 编辑分类 -->
    <el-dialog
      width="40%"
      title="编辑商品分类"
      :append-to-body="true"
      :visible.sync="outerVisiblier"
      v-if="outerVisiblier" 
    >
    
     <el-form :model="modificationdate">
      <el-form-item  label="城市" required>
              <v-distpicker
               @province="onprovinceo"
            
                class="v-distpicker"
               @selected="addresscity"
                :province="modificationdate.provinceName"
                :city="modificationdate.cityName"
                  hide-area
              ></v-distpicker>
            </el-form-item>
              <el-form-item label="banner" required>
             <el-upload
              action=""
              :limit="1"
              :http-request="modificationPortrait"
               list-type="picture-card"
              :on-preview="modificationfidcard"
              :on-remove="odificationfidReversesideofidcard"
          
            >
              <i class="el-icon-plus"></i>
            </el-upload>
                  <el-dialog :visible.sync="modificationDoor" :append-to-body="true">
              <img width="100%" :src="modificationUrl" alt="" />
            </el-dialog>
                 <div style="margin: 0px 15px 0px 70px">
            <template>
              <img @click="ondialogimgsrc(icon)" :src="icon" alt="" width="150px" style="margin: 15px 15px 0px 0" >
            </template>
          </div>
        </el-form-item>
              <!-- <el-form-item label="详情页跳转位置" >
          <el-col class="mr15" :span="12">
            <el-input size="mini"   placeholder="请输入跳转位置"  v-model="modificationdate.detailsImgAndPageUrl"  ></el-input>
          </el-col>
        </el-form-item>
           <el-form-item label="跳转轮播位置" >
          <el-col class="mr15" :span="12">
            <el-input size="mini"  placeholder="请输入跳转位置"  v-model="modificationdate.jumpPosition"  ></el-input>
          </el-col>
        </el-form-item> -->
           <el-form-item label="跳转活动位置" >
          <el-col class="mr15" :span="12">
            <el-input size="mini"  placeholder="请输入活动链接"  v-model="modificationdate.detailsImgAndPageUrl"  ></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="显示状态" required>
          <el-radio v-model="modificationdate.showHide" :label="0">显示</el-radio>
          <el-radio v-model="modificationdate.showHide" :label="1">隐藏</el-radio>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" @click="outerVisiblier = false">取消</el-button>
        <el-button type="primary" @click="modificationVisible">确定</el-button>
      </span>
    </el-dialog>
    <!-- 添加分类 -->
    <el-dialog
      width="40%"
      title="新增首页banner"
      :append-to-body="true"
      :visible.sync="outerVisibler"
      
      v-if="outerVisibler" 
      
    >
      <el-form :model="subcategoryList">
      <el-form-item  label="城市" required>
              <v-distpicker
               @province="onprovinceo"
            
                class="v-distpicker"
               @selected="addresscity"
                :province="subcategoryList.provinceName"
                :city="subcategoryList.cityName"
                  hide-area
              ></v-distpicker>
            </el-form-item>
              <el-form-item label="banner" required>
             <el-upload
              action=""
              :limit="1"
              :http-request="upIdentityCardPortrait"
               list-type="picture-card"
              :on-preview="handleReversesideofidcard"
              :on-remove="handleRemoveReversesideofidcard"
          
            >
              <i class="el-icon-plus"></i>
            </el-upload>
                  <el-dialog :visible.sync="IdentityCardPortraitDoor" :append-to-body="true">
              <img width="100%" :src="IdentityCardPortraitUrl" alt="" />
            </el-dialog>
        </el-form-item>
              <el-form-item label="跳转活动位置" >
          <el-col class="mr15" :span="12">
            <el-input size="mini"   placeholder="请输入跳转链接"  v-model="subcategoryList.detailsImgAndPageUrl"  ></el-input>
          </el-col>
        </el-form-item>
           <!-- <el-form-item label="跳转轮播位置" >
          <el-col class="mr15" :span="12">
            <el-input size="mini"  placeholder="请输入跳转位置"  v-model="subcategoryList.jumpPosition"  ></el-input>
          </el-col>
        </el-form-item> -->
        <el-form-item label="显示状态" required>
          <el-radio v-model="subcategoryList.showHide" :label="0">显示</el-radio>
          <el-radio v-model="subcategoryList.showHide" :label="1">隐藏</el-radio>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" @click="initializedate()">取消</el-button>
        <el-button type="primary" @click="addinsertGoodCategoryForPC()">确定</el-button>
      </span>
    </el-dialog>
     <!-- 列表大图展示 -->
     <el-dialog :visible.sync="dialogimg"  :append-to-body="true" >
              <img width="100%" :src="dialogimgsrc" alt="" />
            </el-dialog>
               <!-- 活动链接展示 -->
     <el-dialog  style='overflow:scroll;overflow-y:hidden' title="活动页面" :visible.sync="activity"  :append-to-body="true"  width="445px">

             <iframe  :src="activityurl" width="400px" height="507px" ></iframe>
          
            </el-dialog>
  </div>
</template>


<script >
import {delActivityRecommended,updateActivityRecommended,searchCityList,searchProvinceList,insertActivityRecommended, searchActivityRecommendedList,getCategoryList,insertGoodCategoryForPC,searchActivityRecommendedById } from "@/api";
export default {
  data() {
    return {
      activityurl:"",
      activity:false,
      dialogimgsrc:"",
      // 编辑图片
        modificationDoor:false,
            modificationUrl:"",
      // 修改轮播图
      modificationdate:{},
      //上传ion
      IdentityCardPortraitDoor:false,
      IdentityCardPortraitUrl:"",
      outerVisiblier: false,
      outerVisibler: false,
      dialogVisible: false,
      tableData: [],
       loading: false,
      // 查找商品列表参数对象
      queryInfo: {
        page: 1,
        size: 10,
       cityCode: "",
       provinceName:"",
       name: "",
       
      },
      icon:"",
      options:[],
      // 添加列表
      subcategoryList:{
  showHide:0,
      },
        showHide:true,
      total: 0,
      dialogimgsr:"",
      dialogimg:false,
      CityList:{},
    };
  },
  created(){
   this.rers()
   this.searchCityLists()


  },
  methods: {
    // 点击活动链接
    onactivityurl(e){
      console.log(e);
    if(e){
       this.activityurl=e
      this.activity=true
    }
    },
        ondialogimgsrc(data){
this.dialogimgsrc=data
this.dialogimg=true
    },
    showHidece(e,i){
      this.modificationdate=i
      if(e){
        this.modificationdate.showHide=0
      }else{
        this.modificationdate.showHide=1
      }
this.modificationVisible()
    },
      async deleter(id){
          const formData = new FormData()
       formData.append("ids", id);
  const { data: res } = await delActivityRecommended(formData)
  if (res.code !== 200) return this.$message.error("删除失败请稍后重试");
  this.$message.success("删除成功");
     this.loading=false
   this.getGoodsList();
},
    // 点击确定
  async  modificationVisible(){
    
   if(!this.modificationdate.cityCode) return this.$message.error("请选择城市");
 if(!this.modificationdate.activityImgUrl) return this.$message.error("请选择上传icon");
   const { data: res } = await  updateActivityRecommended(this.modificationdate)

   if (res.code !== 200) return this.$message.error("修改失败请稍后重试");

      this.loading=false
   this.outerVisiblier=false
   this.$message.success('修改成功')
   this.getGoodsList()
    },
    // 点击删除图片
    odificationfidReversesideofidcard(){
this.modificationdate.activityImgUrl=""
    },
    // 点击查看图片
    modificationfidcard(){
   
  this.modificationDoor=true
      this.modificationUrl=this.modificationdate.activityImgUrl
    },
    // 上传图片
   
        async   modificationPortrait(file){
     const formData = new FormData()
       formData.append("file", file.file);
       formData.append("type",1)
      const { data: res } = await this.$http.post(`/api/platform/uploadImages`,formData);
       if (res.code !== 200) return this.$message.error("上传失败");
      this.modificationdate.activityImgUrl= res.body[0].split("?")[0];
  
      this.$message.success("上传成功");
    },
    // 获取城市
  async   rers(){
         const { data: rers } = await  searchProvinceList()
        if (rers.code !== 200) return this.$message.error("信息获取失败请稍后重试");
        this.cityCode= rers.body
    },
      async searchCityLists(){
         const { data: rers } = await  searchCityList()
        if (rers.code !== 200) return this.$message.error("信息获取失败请稍后重试");
        this.CityList= rers.body.cityAll
        await this.getGoodsList()
    },
    
    // 点击打开编辑
   async modificationressVisiblier(ids){
      const { data: res } = await  searchActivityRecommendedById({id:ids})
       if (res.code !== 200) return this.$message.error("信息获取失败请稍后重试");
   
       this.modificationdate=res.body
       this.icon= this.modificationdate.activityImgUrl
        this.cityCode.forEach(element => {
          if(element.provinceCode==this.modificationdate.cityCode.substring(0,2)){
       return  this.modificationdate.provinceName=element.provinceName
          }
        });
      
         const { data: date } = await searchCityList()
           date.body.cityAll.forEach(element => {
 
          if(element.cityCode==this.modificationdate.cityCode){
           
       this.modificationdate.cityName=element.cityName
       
          }
          
        });

       this.outerVisiblier=true
    },
        // 添加轮播图
  async addinsertGoodCategoryForPC(){

   if(!this.subcategoryList.cityCode) return this.$message.error("请选择城市");
   if(!this.subcategoryList.activityImgUrl) return this.$message.error("请上传banner");
   this.subcategoryList.type=0
   const { data: res } = await  insertActivityRecommended(this.subcategoryList)
   if (res.code !== 200) return this.$message.error("添加失败请稍后重试");
   this.outerVisibler=false
   this.getGoodsList()
   this.$message.success('添加成功')

    this.initializedate()
    },
    // 初始化添加分类数据
    initializedate(){
   const obj ={
   showHide:0
      }
this.subcategoryList=obj
this.options=[]
this.outerVisibler=false
   this.loading=false

    },
    // 查看上传
        handleReversesideofidcard(){
  this.IdentityCardPortraitUrl=this.subcategoryList.activityImgUrl
    this.IdentityCardPortraitDoor=true
    },
    // 删除上传
    handleRemoveReversesideofidcard(){
this.subcategoryList.activityImgUrl=""
    },
    // 上传ion
      async  upIdentityCardPortrait(file){
     const formData = new FormData()
       formData.append("file", file.file);
       formData.append("type",1)
      const { data: res } = await this.$http.post(`/api/platform/uploadImages`,formData);
       if (res.code !== 200) return this.$message.error("上传失败");
      this.subcategoryList.activityImgUrl= res.body[0].split("?")[0];
      this.$message.success("上传成功");
    },
    /*
     * 页面加载请求所有的商品数据
     * 默认请求第一页 十条数据
     * */
    async getGoodsList() {
        const { data: res } = await searchActivityRecommendedList(this.queryInfo);
        if (res.code !== 200) return this.$message.error("轮播图列表获取错误");
        this.tableData = res.body.list
this.tableData.forEach((element,i )=> {
if(element.showHide===0){
this.tableData[i].showHide=true
}else{
  this.tableData[i].showHide=false
}
  
for (let j = 0; j < this.CityList.length; j++) {
if(this.tableData[i].cityCode==this.CityList[j].cityCode){

return this.tableData[i].cityName=this.CityList[j].cityName
}
  
}
 
});


        this.total = res.body.total;
       this.loading=false
    },

    handleCurrentChange(data) {
      this.queryInfo.page = data;
      this.getGoodsList();
    },

 //店铺名称查询
 queryname(){
    this.queryInfo.page = 1;
      this.getGoodsList();
 },
  //城市选择
  addresscity(data){
  if(data.city.code){
    this.subcategoryList.cityCode=data.city.code.substring(0,4)
   this.modificationdate.cityCode=data.city.code.substring(0,4)
  }else{
   this.subcategoryList.cityCode=""
   this.modificationdate.cityCode=""
  }

    },
//  分页改变每页数量
    handleSizeChange(newSize) {
      this.queryInfo.page = 1;
      this.queryInfo.size = newSize;
      this.getGoodsList();
     
    },
 onprovinceo(date){

this.subcategoryList.provinceName=date.value

 },
 addprovince(date){
  if(date.value=="省"){
this.modificationdate.provinceName=""
  }else{
this.modificationdate.provinceName=date.value
  }
  

 },
 // 选择省
 onprovince(data){
  this.queryInfo.page = 1;
      this.queryInfo.province = data.value;
          this.loading=false
      this.getGoodsList();
 },
    // 选择市
   oncity(data) {
    this.queryInfo.page = 1;
  if(data.code){

     this.queryInfo.cityCode= data.code.substring(0,4)
  }else{
     this.queryInfo.cityCode= ""
  }
      
      this.getGoodsList();
    },

    // 退出
    logout() {
      window.sessionStorage.clear();
      this.$router.push("/login");
    },
    // 返回首页
    backHome() {
      this.$router.push("/index");
      window.sessionStorage.removeItem("activePath");
    },
  },
  watch:{
    outerVisiblier(i,d){
  if(!i){
      this. initializedate()
     }
    },
    outerVisibler(i,d){
     if(!i){
      this. initializedate()
     }
    }
  }
};
</script>

<style scoped lang="scss">
  iframe ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  /* 滚动槽 */
  iframe ::-webkit-scrollbar-track {
    border-radius: 2px;
    background: #f4f4fa;
  }
  /* 滚动条滑块 */
  iframe ::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: #da1572;
  }
p {
  margin: 0;
  padding: 0;
}

ol,
ul {
  margin: 0;
  padding: 0;
}

.content ul {
  list-style: none;

  li {
    display: flex;
    align-items: center;
    margin-top: 25px;

    span {
      font-weight: bold;
      display: block;
      width: 120px;
    }

    img {
      display: block;

      width: 100px;
    }
  }
}
.CheckInTimeTitle {
  font-size: 12px;
  color: #606266;
  margin-right: 5px;
}
.Price {
  display: flex;
  flex-wrap: wrap;
}

.resetInputSize {
  width: 100px;
}

.quill-editor {
  line-height: normal;
}

.goodDesc p img {
  width: 100px;
}
.el-buttonr {
  width: 70px;
  height: 25px;
}
.goods-details {
  .el-row {
    margin-bottom: 10px;
  }

  .goodDesc {
    p {
      img {
        width: 100px !important;
      }
    }
  }
}
.v-distpicker ::v-deep select {
  height: 29px !important;

  padding-top: 0.4rem;
  font-size: 12px;
}
.el-tag + .el-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
.el-icon-s-fold,
.el-icon-s-unfold {
  font-size: 35px;
  margin: 22px;
  color: #999;
}
.mr15 {
  margin-right: 15px;
}
</style>
  